import IconCheckFilled from '@icons/CheckFilled'
import Button from '@global/Button'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import * as S from './EnquirySuccessMessage.styled'

const EnquirySuccessMessage = ({ message }) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()

  return (
    <S.EnquirySuccessMessage>
      <S.EnquiryStatus>
        <IconCheckFilled />
        <span>Enquiry Sent</span>
        <p>{message}</p>
      </S.EnquiryStatus>
      <h3>Thanks for contacting us.</h3>
      <p>
        Our dedicated team of experts will carefully review the information you
        provided and get back to you promptly with further details.
        <br />
        <br />
        Once again, thank you for choosing Barry Plant. We appreciate your trust
        in our expertise and look forward to providing you with service that
        exceeds your expectations
      </p>
      <Button color='primary' onClick={() => setShowEnquiryForm(false)}>
        Close
      </Button>
    </S.EnquirySuccessMessage>
  )
}

export default EnquirySuccessMessage

import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'
import { UrlBuilder } from '../helpers'

export const appraisalRequest = async options => {
  return await apiCall(apis.APPRAISAL_REQUEST, options, 'post')
}

export const getThankYouPageData = async (thankYouPageUrl) => {
  return await apiCall(thankYouPageUrl)
}

import Button from '@global/Button'
import { Col, Row } from '@styles/global.styled'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Field from '@global/FormInput/Field'
import IconArrowRight from '@icons/ArrowRight'
import { adfenix } from '@lib/adfenixHelper'
import { useEffect, useRef, useState } from 'react'
import { logEvent } from '@lib/analyticsService'
import {
  sendProjectEnquiry,
  sendPropertyEnquiry,
} from '@lib/api-services/formsService'
import { appraisalRequest } from '@lib/api-services/appraisalService'
import { toast } from 'react-toastify'
import EnquirySuccessMessage from './EnquirySuccessMessage'
import EnquiryFormNav from './EnquiryFormNav'
import Image from '@global/Image'
import { agentEnquiryForm } from '@lib/api-services/agentsService'
import { officeEnquiryForm } from '@lib/api-services/officesService'
import * as S from './EnquiryForm.styled'
import nProgress from 'nprogress'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ApiKeys } from '@lib/variables'
import dynamic from 'next/dynamic'

const ReCaptcha = dynamic(
  () => import('react-recaptcha-v3').then((mod) => mod.ReCaptcha),
  {
    ssr: false,
  }
)

const EnquiryFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  enquiry_type: Yup.string(),
  recaptchar: '',
  email_address: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  message: Yup.string().required('Message is required'),
})

const ENQUIRY_FORM_TYPE = {
  LISTING: 'listing',
  AGENT: 'agent',
  OFFICE: 'office',
  APPRAISAL: 'appraisal',
  PROJECT: 'project',
}

const EnquiryForm = ({
  headerInfo,
  type = 'listing',
  listing,
  projectAddress,
  officePageTitle,
  agentName,
  agentSlug,
  customHeader,
}) => {
  const recaptchaRef = useRef()
  const [successMessage, setSuccessMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { isShowEnquiryForm } = useEnquiryFormContext()

  const reloadRecaptcha = () => {
    if (window.grecaptcha && recaptchaRef.current) {
      const { sitekey, action, verifyCallback } = recaptchaRef.current.props
      window.grecaptcha.execute(sitekey, { action }).then((token) => {
        if (typeof verifyCallback !== 'undefined') {
          verifyCallback(token)
        }
      })
    }
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    nProgress.start()

    if (type === ENQUIRY_FORM_TYPE.LISTING) {
      const requestBody = {
        ...values,
        listing: listing.id,
      }
      const res = await sendPropertyEnquiry(requestBody)
      if (res.ok) {
        adfenix.bookshowing()
        logEvent(
          'Property',
          listing.address_display_string + ' - ' + listing.id,
          'Enquiry Sent',
          3
        )
        setIsLoading(false)
        setSuccessMessage(
          <>
            {listing?.address_street_display ? (
              <>
                {listing?.address_street_display}
                <br />
              </>
            ) : (
              ''
            )}
            {listing?.address_suburb} {listing?.address_state}{' '}
            {listing?.address_postcode}
          </>
        )
        nProgress.done()
        return
      } else {
        reloadRecaptcha()
      }
    }

    if (type === ENQUIRY_FORM_TYPE.PROJECT) {
      const res = await sendProjectEnquiry({
        ...values,
        project: headerInfo.id,
      })

      if (res.ok) {
        adfenix.bookshowing()
        logEvent(
          'Project',
          projectAddress + ' - ' + headerInfo.id,
          'Enquiry Sent',
          3
        )
        setIsLoading(false)
        setSuccessMessage(
          <>
            {listing?.address_street_display ? (
              <>
                {listing?.address_street_display}
                <br />
              </>
            ) : (
              ''
            )}
            {listing?.address_suburb} {listing?.address_state}{' '}
            {listing?.address_postcode}
          </>
        )
        nProgress.done()
        return
      }
    }

    if (type === ENQUIRY_FORM_TYPE.AGENT) {
      const res = await agentEnquiryForm({ ...values, agent: headerInfo.id })

      if (res.ok) {
        adfenix.contactrequestsubmit()
        logEvent('Agent Contact Enquiry', agentName, window.location.pathname)
        setIsLoading(false)
        setSuccessMessage(
          <>
            {headerInfo.title}
            <br />
            {headerInfo.description}
          </>
        )
        nProgress.done()
        return
      }
    }

    if (type === ENQUIRY_FORM_TYPE.OFFICE) {
      const res = await officeEnquiryForm({ ...values, office: headerInfo.id })
      if (res.ok) {
        logEvent(
          'Office Contact Enquiry',
          officePageTitle,
          window.location.pathname
        )
        setIsLoading(false)
        setSuccessMessage(headerInfo.title)
        nProgress.done()
        return
      }
    }

    if (type === ENQUIRY_FORM_TYPE.APPRAISAL) {
      adfenix.bookappraisal()

      const res = await appraisalRequest({
        ...values,
        office_agent_slug: agentSlug,
      })
      if (res.ok) {
        logEvent('Forms', 'Submit appraisal request', values.property_type)
        setIsLoading(false)
        nProgress.done()
        return
      }
    }

    nProgress.done()
    toast.error('An error occurred submitting form.')
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isShowEnquiryForm) {
      setSuccessMessage('')
    }
  }, [isShowEnquiryForm])

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(ApiKeys.RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  return isShowEnquiryForm ? (
    <S.FormWrapper active isSuccess={!!successMessage}>
      <EnquiryFormNav />
      <S.EnquiryForm>
        {successMessage ? (
          <EnquirySuccessMessage message={successMessage} />
        ) : (
          <>
            {customHeader ? (
              customHeader
            ) : (
              <S.EnquiryFormHeader>
                <Image src={headerInfo.image} alt={headerInfo.title} />
                <div>
                  <S.EnquiryFormHeaderTitle>
                    {headerInfo.title}
                  </S.EnquiryFormHeaderTitle>
                  <S.EnquiryFormHeaderDes>
                    {headerInfo.description}
                  </S.EnquiryFormHeaderDes>
                </div>
              </S.EnquiryFormHeader>
            )}
            <S.EnquiryFormBody>
              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email_address: '',
                  enquiry_type: 'any',
                  recaptcha: '',
                  phone_number: '',
                  message: '',
                  agree_to_receive: false,
                }}
                validationSchema={EnquiryFormSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  getFieldProps,
                  setFieldValue,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <ReCaptcha
                      sitekey={ApiKeys.RECAPTCHA_KEY}
                      action='referral'
                      verifyCallback={async (token) => {
                        if (values.recaptcha !== token) {
                          setFieldValue('recaptcha', token, false)
                        }
                      }}
                      ref={recaptchaRef}
                    />
                    <Field
                      label='First name'
                      required
                      {...getFieldProps('first_name')}
                      error={touched.first_name && errors.first_name}
                    />
                    <Field
                      label='Last name'
                      required
                      {...getFieldProps('last_name')}
                      error={touched.last_name && errors.last_name}
                    />
                    <Row gutter={12}>
                      <Col md={6}>
                        <Field
                          label='Email'
                          required
                          {...getFieldProps('email_address')}
                          error={touched.email_address && errors.email_address}
                        />
                      </Col>
                      <Col md={6}>
                        <Field
                          label='Contact number'
                          required
                          {...getFieldProps('phone_number')}
                          error={touched.phone_number && errors.phone_number}
                        />
                      </Col>
                    </Row>
                    <Field
                      label='Your message'
                      required
                      type='textarea'
                      {...getFieldProps('message')}
                      error={touched.message && errors.message}
                    />
                    <S.EnquiryFormFooter>
                      <Field
                        label={
                          <span>
                            I’d like to receive Barry Plant updates and insights
                            (no spam, only occasional updates){' '}
                            <a href='/privacy-policy' target='_blank'>
                              Privacy Policy
                            </a>
                          </span>
                        }
                        type='checkbox'
                        name={'agree_to_receive'}
                        {...getFieldProps('agree_to_receive')}
                      />
                      <div>
                        <Button
                          type='submit'
                          color='primary'
                          disabled={isLoading}
                          endIcon={<IconArrowRight />}
                        >
                          Send enquiry
                        </Button>
                      </div>
                    </S.EnquiryFormFooter>
                  </form>
                )}
              </Formik>
            </S.EnquiryFormBody>
          </>
        )}
      </S.EnquiryForm>
    </S.FormWrapper>
  ) : null
}

export default EnquiryForm

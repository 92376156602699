import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import { Link } from '@routes'
import IconCloseCircle from '@icons/CloseCircle'
import * as S from './EnquiryFormNav.styled'

const EnquiryFormNav = () => {
  const { isShow, setShowEnquiryForm } = useEnquiryFormContext()

  return (
    <S.EnquiryFormNav>
      <S.Logo onClick={() => setShowEnquiryForm(false)}>
        <Link route='/' passHref>
          <img src={'/imgs/logo.svg'} alt='Barryplant' />
        </Link>
      </S.Logo>
      <S.CloseButton onClick={() => setShowEnquiryForm(false)}>
        <IconCloseCircle />
      </S.CloseButton>
    </S.EnquiryFormNav>
  )
}

export default EnquiryFormNav

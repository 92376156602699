import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const Logo = styled.div`
  position: fixed;
  top: 24px;
  left: 28px;

  a {
    font-size: 0;
    line-height: 0;
  }

  img {
    height: 30px;
  }

  @media ${DEVICES.mobile} {
    left: 20px;
    top: 14px;
  }
`

export const CloseButton = styled.div`
  cursor: pointer;
  position: fixed;
  top: 22px;
  right: 40px;
  width: 32px;
  height: 32px;
  z-index: 99999;

  @media ${DEVICES.mobile} {
    right: 20px;
    top: 16px;
  }

  svg {
    color: ${COLORS.secondary};
  }
`

export const EnquiryFormNav = styled.div`
  height: 76px;
  position: fixed;
  background: ${COLORS.white};
  top: 0;
  left: 0;
  width: 100%;

  @media ${DEVICES.mobile} {
    height: 62px;
  }
`

import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text3XL, textLG } from '@styles/fonts'

export const EnquirySuccessMessage = styled.div`
  text-align: center;

  h3 {
    ${text3XL};
    font-family: ${FONTS.medium};
    margin-bottom: 40px;
    text-transform: none;
    letter-spacing: 0.1;
  }

  p {
    color: ${COLORS.secondary};
    margin-bottom: 64px;
  }

  @media ${DEVICES.mobile} {
    padding-top: 70px;
  }
`

export const EnquiryStatus = styled.div`
  margin-bottom: 64px;

  svg {
    margin-bottom: 16px;
  }

  span {
    ${textLG};
    text-transform: uppercase;
    font-family: ${FONTS.medium};
    display: block;
    color: ${COLORS.secondary};
    letter-spacing: 1.2px;
    margin-bottom: 4px;
  }
`
